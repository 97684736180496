@import './variables';

form {
  width: 100%;

  .mat-form-field {
    .errors {
      position: absolute;
      top: 100%;
      right: 0;

      span {
        font-size: 12px;
        color: $warn;
      }
    }
  }
  
}

.mat-form-field {
  // min-width: 256px;

  &.rounded {
    display: block;

    &.mat-form-field-invalid  {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-outline {
            color: $warn;
          }
        }
      }
    }

    .mat-form-field-wrapper {
      .mat-form-field-flex {
        padding: 0 1rem 0 1rem;
        align-items: center;

        .mat-form-field-outline {
          color: $primary;

          .mat-form-field-outline-start {
            border-radius: 40px 0 0 40px;
          }
          .mat-form-field-outline-end {
            min-width: 30px;
            border-radius: 0 40px 40px 0;
          }
        }
  
        .mat-form-field-prefix {
          top: 0.05rem;
          margin-right: 0.5rem;
        }

        .mat-form-field-suffix {
          top: 0.05rem;
          margin-left: 0.5rem;
        }
  
        .mat-form-field-infix {
          height: 48px;
          line-height: 22px;
          border: none;
  
          .mat-form-field-label-wrapper {
            display: none;
          }
        }
      }
    }
  }

  &.outline {
    display: block;

    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-outline {
          .mat-form-field-outline-gap {
            border-top-color: currentColor;
          }
        }
  
        .mat-form-field-infix {
          padding: 2rem 0 0.5rem 0;
          border: none;
  
          .mat-form-field-label-wrapper {
            .mat-form-field-label {
              transform: translateY(2px) scale(0.75);
            }
          }
        }
      }
    }
  }

  .mat-icon {
    color: $primary;
  }

}

.mat-checkbox {
  width: 100%;

  &.mat-checkbox-checked {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        .mat-checkbox-background {
          background-color: transparent;
        }
      }
    }
  }

  .mat-checkbox-layout {
    display: block;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    background-color: $grey-light;
    color: $primary;
    font-weight: 300;

    .mat-checkbox-inner-container {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      border-radius: 5px;
      background-color: $primary;

      .mat-checkbox-frame {
        border-color: transparent;
      }
    }
  }
}

.delete-wrapper {
  position: relative;
  
  .delete-button {
    position: absolute;
    left: 100%;
    top: 6px;
  }
}

@media (min-width: 768px) {
  form {
    .fields-group {
      display: flex;
      align-items: center;
  
      .mat-form-field {
        flex: 1;

        &:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }
  }
}