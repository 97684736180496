/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import './assets/styles/theme.scss';
@import './assets/styles/variables';
@import './assets/styles/reset';
@import './assets/styles/forms';
@import './assets/styles/texts';
@import './assets/styles/button';
@import './assets/styles/table';

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html, body { 
  height: 100%; 
}

body {
  font-family: 'Roboto', sans-serif;
  color: $grey;
}