.title {
  margin-bottom: 0.5rem;
  color: $primary;
  font-size: 22px;
  font-weight: 700;
}

a {
  font-weight: 700;
  color: $primary;
}

.messages {
  margin-top: 1rem;
}

.error-message {
  color: $warn;
}