
button, a {
  &.mat-button,
  &.mat-stroked-button,
  &.mat-flat-button {
    min-width: 180px;
    max-width: 400px;
    padding: 0.25rem 2rem;
    border-radius: 40px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
  }

  &.mat-calendar-period-button {
    min-width: auto;
    border-radius: 0;
    padding: 0 16px;
  }
}

form {
  button {
    &.mat-button,
    &.mat-stroked-button,
    &.mat-flat-button {
      width: 100%;
    }
  }
}