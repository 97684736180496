table {
  width: 100%;

  tr.mat-row:hover {
    background-color: whitesmoke;
  }
}

.mat-table {
  .mat-header-row {
    .mat-header-cell {
      color: $primary;
      font-size: 14px;
    }
  }
}

.data-table {

  .head {
    padding-bottom: 0.5rem;
    margin-bottom: 1.2rem;
    border-bottom: 1px solid $grey;

    .title {
      margin-bottom: 0.5rem;
    }

    .align-right {

      .mat-button,
      .mat-stroked-button,
      .mat-flat-button {
        display: block;
        max-width: none;
        margin-bottom: 0.5rem;
      }

      .search {
        .mat-form-field-wrapper {
          padding-bottom: 0;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .align-right {
        display: flex;
        align-items: center;

        .mat-button,
        .mat-stroked-button,
        .mat-flat-button {
          margin-bottom: 0;
          margin-right: 1rem;
        }

        .search {
          min-width: 320px;
        }
      }
    }
  }

}
